@tailwind base;
@tailwind components;
@tailwind utilities;

/* ###################### SCROLL BAR ###################### */

* {
    scroll-behavior: smooth;
}

body {
    @apply bg-whiteTheme;
    --sb-track-color: #cfcfcf;
    --sb-thumb-color: #2563eb;
    --sb-size: 10px;
}

body::-webkit-scrollbar {
    width: var(--sb-size)
}

body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
}

body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 25px;

}

@supports not selector(::-webkit-scrollbar) {
    body {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}

/* ############################################### */

.title {
    @apply text-center text-2xl sm:text-3xl md:text-4xl font-semibold font-poppins mb-5 md:mb-12;
}

.blue__gradient {
    background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #2e9aff80 100%);
    filter: blur(100px);
}