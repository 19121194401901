/* #################### NAVBAR #################### */

.nav {
  @apply mx-auto max-w-screen-xl backdrop-blur-[10px] bg-blue-900/5 fixed left-0 right-0 z-50
}

.nav-section {
  @apply flex justify-between items-center h-[70px] px-5 sm:px-20;
}

.nav-links {
  @apply hover:text-blue-600 hover:scale-105 duration-100 hover:cursor-pointer;
}

.lang-indicator {
  @apply w-4 h-[10px] relative right-2 rounded-full bg-white text-[8px] flex justify-center items-center font-medium;
}

.lang-dropdown {
  @apply absolute p-1 rounded-lg bg-gradient-to-r from-blue-200 to-blue-500 backdrop-blur-[10px];
}

.lang-option {
  @apply w-16 flex justify-between p-1 rounded hover:bg-gray-100/40 cursor-pointer;
}

/* #################### HEADER #################### */

.homeHeader {
  @apply w-full pt-20 md:h-[85vh] lg:h-[95vh] lg:max-h-[560px] bg-gradient-to-b from-[#ced0d7] to-whiteTheme mb-1;
}

.imgsContainer {
  @apply w-full max-md:h-[100%] flex items-center overflow-hidden;
}

.robotHand {
  @apply w-6/12 relative -top-5 md:-top-14 lg:-top-20 object-cover;
}

.humanHand {
  @apply w-6/12 relative top-4 sm:top-10 object-cover;
}

.sloganContainer {
  @apply absolute left-0 right-0 top-32 text-center mx-auto px-8 sm:px-16 max-sm:mt-4 w-8/12 2xl:w-6/12 slogan tracking-wide;
}

.slogan {
  @apply font-semibold leading-none;
  font-family: "Outfit", sans-serif;
  filter: drop-shadow(0 20px 35px rgba(255, 255, 255, 0.6));
}

/* #################### SERVICES #################### */

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  filter: brightness(0.8);
  transform: scale(0.8);
}

.swiper-slide.swiper-slide-active {
  filter: brightness(1);
  transform: scale(1.15);
}


.swiper-slide.swiper-slide-active>div {
  border: .5px solid rgba(153, 153, 153, 0.463);
}

@media screen and (max-width: 700px) {
  .swiper {
    max-width: 520px;
  }
}

@media screen and (max-width: 420px) {
  .swiper-slide>div {
    width: 260px;
  }
}

.sidebar {
  -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.swipeDiv {
  @apply max-w-xs bg-white shadow-lg rounded-lg aspect-[16/11] border
}

.slideDiv {
  @apply px-4 py-6 flex flex-col justify-center items-center
}

.slideButton {
  @apply mt-5 max-sm:text-xs p-2 sm:px-3 sm:py-2 rounded-md border border-blue-600 text-blue-600 mx-auto hover:bg-blue-600 hover:text-white duration-100
}

/* #################### FADE IMAGES #################### */

.fadeContainer {
  @apply relative w-full h-[80vh] -z-[5] pt-[140px] flex justify-center items-center
}

.imgFade {
  @apply absolute w-full h-full object-cover opacity-0;
  transition: opacity 1s ease-in-out;
}

.showing {
  opacity: 1;
}

.dotsContainer {
  @apply flex justify-center -mt-5
}

.dots {
  @apply text-6xl sm:text-[70px] md:text-[80px] hover:text-white cursor-pointer
}

/* #################### PARTNERS #################### */

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  position: relative;
  display: flex;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 20%;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: flex;
  flex-shrink: 0;
  animation: 15s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

@media screen and (max-width: 680px) {
  .logos {
    padding: 40px 0;
  }

  .logos-slide img {
    height: 30px;
    margin: 0 30px;
  }
}


/* #################### PARTNERS #################### */

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}